<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_office.groupDetail') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="110px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Axios from 'axios'

const BASEURL = {
  get: '/system/office/get',
  save: '/system/office/save',
  update: '/system/office/update'
}
export default {
  name: 'SystemGroupDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: { parent: {} },
      tableData: []
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },

    category () { return this.$getDictList('sys_office_category') },
    type () { return this.$getDictList('sys_office_type') },

    formField () {
      return [
        {
          prop: 'name',
          label: this.$t('system_office.name'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'nameEn',
          label: this.$t('system_office.nameEn'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'level',
          label: this.$t('system_office.level'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          component: { disabled: true },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        // {
        //   prop: 'category',
        //   label: this.$t('system_office.category'),
        //   type: 'Select',
        //   component: { optionList: this.category, disabled: true },
        //   rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        // },
        {
          prop: 'sort',
          label: this.$t('system_office.sort'),
          type: 'NumberInput',
          component: { min: 0 },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        // {
        //   prop: 'code',
        //   label: this.$t('system_office.code'),
        //   type: 'Input',
        //   col: { xs: 12, sm: 12, md: 12 },
        //   rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        // },
        {
          prop: 'strategicDealerGroups',
          label: this.$t('system_office.strategicDealerGroups'),
          type: 'Select',
          col: { xs: 12, sm: 12, md: 12 },
          component: { optionList: this.$getDictList('yes_no') },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, this.formModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {
      if (!this.detailData.id) {
        this.formModel.level = 2
        this.formModel.category = 'sys_office_category_2'
        this.$set(this.formModel, 'parent', this.detailData.parent)
        return
      }
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      const requestList = [
        this.$axios.post(BASEURL.get, { id: this.detailData.id })
      ]
      Axios
        .all(requestList)
        .then(Axios.spread((resp) => {
          const respData = resp.data
          this.formModel = respData
        }))
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = { parent: {} }
    }
  }
}
</script>
